<template>
  <div class="s-default-layout">
    <ModalAge
      :show="shouldShowAgeModal"
      @yes="SET_IS_ADULT(true)"
      @no="userIsChild"
    />
    <ModalXLoginPopup />
    <ModalChange />
    <slot />
  </div>
</template>
<script>
  import { mapGetters, mapActions, mapMutations } from 'vuex'

  export default {
    data() {
      return {
        showAgeModal: false
      }
    },
    computed: {
      ...mapGetters('auth', ['xShouldShowAgePopup']),

      shouldShowAgeModal() {
        return this.xShouldShowAgePopup && this.showAgeModal
      }
    },
    mounted() {
      this.$nextTick(() => {
        this.showAgeModal = true
        this.xCloseMobileNavbar()
      })
    },
    methods: {
      ...mapActions(['xCloseMobileNavbar']),
      ...mapMutations('auth', ['SET_IS_ADULT']),

      userIsChild() {
        window.location = 'https://www.google.com'
      }
    }
  }
</script>
