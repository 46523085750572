<template>
  <section class="">
    <ModalPopup
      :show="show"
      classes="s-delivery-modal"
      @close="$emit('close')"
    >
      <template #modal-content>
        <div class="s-age-confirmation-modal-container bg-white flex">
          <div class="left-side">
            <img
              src="/images/flowery-logo-pink.png"
              class="left-side-logo"
              alt="age"
            />
            <div class="left-side-title">
              You look young... <br />
              but like, really <br />
              young tho....
            </div>
            <div class="left-side-subtitle">You're 18 or older, right?</div>

            <div class="left-side-controls flex">
              <lazy-modal-age-btn
                :title="'Yep!'"
                @click-btn="handleYes"
              />

              <div class="divider">OR</div>

              <lazy-modal-age-btn
                :title="'Nope.'"
                @click-btn="$emit('no')"
              />
            </div>

            <div class="checkbox-button-group mb-4">
              <FormRememberMe
                id="rememberMe"
                :option-label="'Remember me'"
                :input-name="'remember'"
                :is-checked="rememberMe"
                @update:checked="rememberMe = $event"
              >
                <template #question> I confirm that this is not a shared device. </template>
              </FormRememberMe>
            </div>
            <div class="left-side-terms">
              By clicking yes and entering the website, I agree to be bound by the <a href="#">Terms of Service</a> and
              <a href="#">Privacy Policy</a>.
            </div>
          </div>
          <div class="right-side">
            <img
              src="/images/flowery-graffiti_2.webp"
              class="right-side-graffiti hidden md:block"
              alt="age image"
            />
          </div>
        </div>
      </template>
    </ModalPopup>
  </section>
</template>

<script>
  export default {
    props: {
      show: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        rememberMe: false
      }
    },
    mounted() {
      this.checkStorage()
    },
    methods: {
      handleYes() {
        this.$emit('yes')
        if (this.rememberMe) {
          const now = new Date()
          const expiryDate = new Date(now.getTime() + 30 * 24 * 60 * 60 * 1000) // 30 days from now
          localStorage.setItem('modalDisabledUntil', expiryDate.toISOString())
        }
      },
      checkStorage() {
        const disabledUntil = new Date(localStorage.getItem('modalDisabledUntil'))
        if (disabledUntil > new Date()) {
          this.$emit('yes')
        }
      }
    }
  }
</script>

<style scoped lang="postcss">
  .checkbox-container {
    margin-top: 20px;
  }

  input[type='checkbox'] {
    margin-right: 10px;
  }

  .s-age-confirmation-modal-container {
    padding: 0;
    height: 100%;

    @media screen and (max-width: 640px) {
      flex-direction: column;
    }
  }

  .modal-close {
    display: none;
  }

  .left-side {
    font-family: DM Mono, serif;
    width: 506px;
    padding-left: 51px;

    @media screen and (max-width: 772px) {
      width: 388px;
      padding-left: 38px;
    }

    @media screen and (max-width: 640px) {
      width: 100%;
      padding-left: 34px;
    }

    .left-side-logo {
      width: 205px;
      height: 38px;
      margin: 52px 0 73px;

      @media screen and (max-width: 772px) {
        width: 152px;
        height: 28px;
        margin: 39px 0 43px;
      }

      @media screen and (max-width: 640px) {
        margin-bottom: 33px;
      }
    }

    .left-side-title {
      font-size: 30px;
      line-height: 42px;
      max-width: 340px;
      margin-bottom: 37px;

      @media screen and (max-width: 772px) {
        font-size: 24px;
        line-height: 36px;
        max-width: 256px;
        margin-bottom: 16px;
      }

      @media screen and (max-width: 640px) {
        font-size: 20px;
        line-height: 30px;
        max-width: 209px;
        margin-bottom: 12px;
      }

      &-link {
        color: #3fb9c5;
      }
    }

    .left-side-subtitle {
      font-weight: 500;
      font-size: 20px;
      line-height: 48px;
      margin-bottom: 20px;

      @media screen and (max-width: 772px) {
        font-size: 14px;
        margin-bottom: 0;
      }

      @media screen and (max-width: 640px) {
        margin-bottom: 3px;
      }
    }

    .left-side-controls {
      margin-bottom: 34px;
      align-items: center;

      .divider {
        padding: 0 18px;
      }

      @media screen and (max-width: 772px) {
        margin-bottom: 31px;

        .divider {
          padding: 0 12px;
        }
      }

      @media screen and (max-width: 640px) {
        margin-bottom: 33px;

        .divider {
          padding: 0 10px;
        }
      }
    }

    .left-side-terms {
      font-size: 12px;
      line-height: 18px;
      color: #7c93a5;
      max-width: 390px;
      padding-bottom: 33px;

      @media screen and (max-width: 772px) {
        font-size: 9px;
        line-height: 14px;
        max-width: 292px;
      }

      @media screen and (max-width: 640px) {
        max-width: 221px;
        margin-bottom: 33px;
      }
    }
  }

  .right-side {
    background: url(/images/texture/graffiti-wall.webp);
    background-size: 100%;
    width: 434px;
    position: relative;

    .right-side-graffiti {
      filter: drop-shadow(0 2px 54px #3fb9c5);
      position: absolute;
      max-width: 200%;
      width: 140%;
      top: 0;
      right: 0;

      @media screen and (max-width: 640px) {
        width: 100%;
        top: -45px;
      }
    }

    @media screen and (max-width: 772px) {
      width: 310px;
    }

    @media screen and (max-width: 640px) {
      width: 292px;
      height: 100%;
    }
  }

  .s-age-confirmation-modal {
    width: 940px;
    height: 600px;
    background: #fff;

    @media screen and (max-width: 772px) {
      width: 698px;
      height: 448px;
    }

    @media screen and (max-width: 640px) {
      width: 292px;
      height: 607px;
    }

    .s-modal-close {
      display: none;
    }
  }
</style>
