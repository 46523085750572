<template>
  <ModalConfirm
    v-if="changeModal"
    :handler="changeModal"
  >
    <template #heading> This Will Clear Your Cart! </template>
    <template #message>
      Changing your your address will clear your cart.
      <br />
      Would you like to continue?
    </template>
  </ModalConfirm>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  export default {
    name: 'ChangeModal',
    computed: {
      ...mapState('modals', ['changeModal'])
    },
    watch: {
      changeModal(handler) {
        if (handler) {
          this.xCloseMobileNavbar()
        }
      }
    },
    methods: {
      ...mapActions(['xCloseMobileNavbar'])
    }
  }
</script>
