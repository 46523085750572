<template>
  <label
    class="inline-flex items-center"
    :class="{ 's-error': invalid }"
  >
    <input
      v-model="checked"
      type="checkbox"
      class="s-registration-checkbox"
      :name="inputName"
      @change="handleCheckboxChange"
    />
    <span>
      <span :class="`s-registration-checkbox-text ` + classes">{{ optionLabel }}</span>
      <br />
      <span class="text-[14px] ml-[26px] s-registration-checkbox-text">
        <slot name="question"></slot>
      </span>
    </span>
  </label>
</template>

<script>
  export default {
    name: 'FormRememberMe',
    props: {
      inputName: {
        type: String,
        default: ''
      },
      classes: {
        type: String,
        default: ''
      },
      optionLabel: {
        required: false,
        type: String,
        default: null
      },
      invalid: {
        type: Boolean,
        default: false
      },
      isChecked: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        checked: this.isChecked
      }
    },
    watch: {
      isChecked(newVal) {
        this.checked = newVal // Ensure internal state is updated if prop changes
      }
    },
    methods: {
      handleCheckboxChange() {
        this.$emit('update:checked', this.checked) // Emitting for v-model in parent
        // Additional logic if needed directly here
      }
    }
  }
</script>

<style scoped lang="postcss">
  .s-registration-checkbox-text {
    color: #002d52;
    @apply font-alt;
    font-size: 14px;
  }

  .s-registration-checkbox {
    display: none;
  }

  .s-registration-checkbox + *::before {
    content: '';
    display: inline-block;
    vertical-align: bottom;
    width: 20px;
    height: 20px;
    margin-right: 0.4rem;

    border-style: solid;
    border-width: 0.1rem;
    border-color: gray;
  }

  .s-registration-checkbox:checked + *::before {
    background: radial-gradient(#3fb9c5 0%, #3fb9c5 40%, transparent 50%, transparent);
    border-color: #95a7b6;
  }

  .s-error {
    color: #fe84b8;

    input {
      border-color: #fe84b8;
    }

    span,
    span::before {
      border-color: #fe84b8;
      color: #fe84b8;
    }
  }
</style>
